<template>
  <!-- Forgot Username Component -->
  <v-container>
    <v-card color="primary">
      <v-card-title>
        <pagetitle
          icon="mdi-account-question-outline"
          title="Forgot Username"
        ></pagetitle>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field color="accent"
                label="Email"
                prepend-icon="mdi-account-circle"
                v-model="email"
                required
                :rules="emailRules"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn outlined @click="onForgotUsername" :disabled="!valid"
            ><v-icon left>mdi-account-question-outline</v-icon>Forgot
            Username</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn outlined @click="onRegister"
            ><v-icon left>mdi-account-plus-outline</v-icon>Register</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn outlined @click="onResetPassword"
            ><v-icon left>mdi-lock-reset</v-icon>Reset Password</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn outlined @click="onLogin"
            ><v-icon left>mdi-login</v-icon>Login</v-btn
          >
          <v-spacer></v-spacer>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import apiService from '@/_services/api-service'
import pagetitle from "@/components/controls/Title"
import router from "@/router"
import store from "@/store"
import breakpointMixin from "../../mixins/breakpointMixin"

export default {
  name: "PasswordReset",
  components: {
    pagetitle,
  },
  data: () => ({
    email: "",
    emailRules: [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Must be valid E-Mail'],
    result: {},
    valid: false,
  }),

  mixins: [breakpointMixin],
  methods: {

    onForgotUsername() {
      const url = 'account/forgotusername/' + this.email
      apiService.post(url, {}, this.successCallback, this.errorCallback)
    },

    successCallback(response) {
      const notification = {
        title: "Forgot Password",
        status: "success",
        messages: [response.data],
      };
      store.commit("setNotification", notification);
    },

    errorCallback(response) {
      const notification = {
        title: "Forgot Password",
        status: "error",
        messages: [response.data],
      };
      store.commit("setNotification", notification);
    },

    onLogin() {
      router.push("/account/login");
    },
    onRegister() {
      router.push("/account/register");
    },
    onResetPassword() {
      router.push("/account/reset");
    },
  },

  router,
  store,
};
</script>
<style lang="scss" scoped>
.v-card {
  margin-right: 15px;
}

.v-btn {
  margin-bottom: 20px;
  margin-left: 10px;
}

</style>
